<template lang="html">
  <section class="legal-warning">
    <div class="container text-legals">
      <h1 class="title">{{$t('menus.footer.legalWarning')}}</h1>
      <article v-html="$t('legalTexts.legalWarning')"></article>
    </div>
  </section>

</template>

<script lang='js'>

  export default {
    name: 'legal-warning',
    props: [],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }


</script>

<style scoped lang='scss'>
  .legal-warning {}
</style>